exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-aesthetic-breast-surgery-sterling-tsx": () => import("./../../../src/pages/aesthetic-breast-surgery-sterling.tsx" /* webpackChunkName: "component---src-pages-aesthetic-breast-surgery-sterling-tsx" */),
  "component---src-pages-aesthetic-breast-surgery-tsx": () => import("./../../../src/pages/aesthetic-breast-surgery.tsx" /* webpackChunkName: "component---src-pages-aesthetic-breast-surgery-tsx" */),
  "component---src-pages-aesthetic-face-surgery-sterling-tsx": () => import("./../../../src/pages/aesthetic-face-surgery-sterling.tsx" /* webpackChunkName: "component---src-pages-aesthetic-face-surgery-sterling-tsx" */),
  "component---src-pages-aesthetic-face-surgery-tsx": () => import("./../../../src/pages/aesthetic-face-surgery.tsx" /* webpackChunkName: "component---src-pages-aesthetic-face-surgery-tsx" */),
  "component---src-pages-aesthetic-genital-surgery-sterling-tsx": () => import("./../../../src/pages/aesthetic-genital-surgery-sterling.tsx" /* webpackChunkName: "component---src-pages-aesthetic-genital-surgery-sterling-tsx" */),
  "component---src-pages-aesthetic-genital-surgery-tsx": () => import("./../../../src/pages/aesthetic-genital-surgery.tsx" /* webpackChunkName: "component---src-pages-aesthetic-genital-surgery-tsx" */),
  "component---src-pages-aesthetic-nose-surgery-sterling-tsx": () => import("./../../../src/pages/aesthetic-nose-surgery-sterling.tsx" /* webpackChunkName: "component---src-pages-aesthetic-nose-surgery-sterling-tsx" */),
  "component---src-pages-aesthetic-nose-surgery-tsx": () => import("./../../../src/pages/aesthetic-nose-surgery.tsx" /* webpackChunkName: "component---src-pages-aesthetic-nose-surgery-tsx" */),
  "component---src-pages-blog-tsx": () => import("./../../../src/pages/blog.tsx" /* webpackChunkName: "component---src-pages-blog-tsx" */),
  "component---src-pages-body-contouring-surgery-sterling-tsx": () => import("./../../../src/pages/body-contouring-surgery-sterling.tsx" /* webpackChunkName: "component---src-pages-body-contouring-surgery-sterling-tsx" */),
  "component---src-pages-body-contouring-surgery-tsx": () => import("./../../../src/pages/body-contouring-surgery.tsx" /* webpackChunkName: "component---src-pages-body-contouring-surgery-tsx" */),
  "component---src-pages-brazilian-lift-sterling-tsx": () => import("./../../../src/pages/brazilian-lift-sterling.tsx" /* webpackChunkName: "component---src-pages-brazilian-lift-sterling-tsx" */),
  "component---src-pages-brazilian-lift-tsx": () => import("./../../../src/pages/brazilian-lift.tsx" /* webpackChunkName: "component---src-pages-brazilian-lift-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-periorbital-aesthetic-surgery-sterling-tsx": () => import("./../../../src/pages/periorbital-aesthetic-surgery-sterling.tsx" /* webpackChunkName: "component---src-pages-periorbital-aesthetic-surgery-sterling-tsx" */),
  "component---src-pages-periorbital-aesthetic-surgery-tsx": () => import("./../../../src/pages/periorbital-aesthetic-surgery.tsx" /* webpackChunkName: "component---src-pages-periorbital-aesthetic-surgery-tsx" */),
  "component---src-pages-skin-surgery-sterling-tsx": () => import("./../../../src/pages/skin-surgery-sterling.tsx" /* webpackChunkName: "component---src-pages-skin-surgery-sterling-tsx" */),
  "component---src-pages-skin-surgery-tsx": () => import("./../../../src/pages/skin-surgery.tsx" /* webpackChunkName: "component---src-pages-skin-surgery-tsx" */),
  "component---src-pages-surgery-prices-tsx": () => import("./../../../src/pages/surgery-prices.tsx" /* webpackChunkName: "component---src-pages-surgery-prices-tsx" */),
  "component---src-pages-thanks-tsx": () => import("./../../../src/pages/thanks.tsx" /* webpackChunkName: "component---src-pages-thanks-tsx" */),
  "component---src-pages-transplantation-sterling-tsx": () => import("./../../../src/pages/transplantation-sterling.tsx" /* webpackChunkName: "component---src-pages-transplantation-sterling-tsx" */),
  "component---src-pages-transplantation-tsx": () => import("./../../../src/pages/transplantation.tsx" /* webpackChunkName: "component---src-pages-transplantation-tsx" */),
  "component---src-pages-tummy-tuck-surgery-sterling-tsx": () => import("./../../../src/pages/tummy-tuck-surgery-sterling.tsx" /* webpackChunkName: "component---src-pages-tummy-tuck-surgery-sterling-tsx" */),
  "component---src-pages-tummy-tuck-surgery-tsx": () => import("./../../../src/pages/tummy-tuck-surgery.tsx" /* webpackChunkName: "component---src-pages-tummy-tuck-surgery-tsx" */),
  "component---src-pages-vaser-sterling-tsx": () => import("./../../../src/pages/vaser-sterling.tsx" /* webpackChunkName: "component---src-pages-vaser-sterling-tsx" */),
  "component---src-pages-vaser-tsx": () => import("./../../../src/pages/vaser.tsx" /* webpackChunkName: "component---src-pages-vaser-tsx" */),
  "component---src-templates-blog-tsx": () => import("./../../../src/templates/blog.tsx" /* webpackChunkName: "component---src-templates-blog-tsx" */),
  "component---src-templates-gallery-tsx": () => import("./../../../src/templates/gallery.tsx" /* webpackChunkName: "component---src-templates-gallery-tsx" */),
  "component---src-templates-page-tsx": () => import("./../../../src/templates/page.tsx" /* webpackChunkName: "component---src-templates-page-tsx" */)
}

