module.exports = [{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":650,"linkImagesToOriginal":true,"showCaptions":false,"markdownCaptions":false,"backgroundColor":"white","quality":50,"withWebp":false,"withAvif":false,"loading":"lazy","decoding":"async","disableBgImageOnAlpha":false,"disableBgImage":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-react-i18next/gatsby-browser.js'),
      options: {"plugins":[],"localeJsonSourceName":"locale","languages":["en","de","it","es"],"defaultLanguage":"en","generateDefaultLanguagePage":true,"i18nextOptions":{"keySeparator":".","ignoreJSONStructure":false,"returnObjects":true},"pages":[{"matchPath":"/","languages":["en","de","it","es"]},{"matchPath":"/aesthetic-face-surgery","languages":["en","de","it","es"]},{"matchPath":"/periorbital-aesthetic-surgery","languages":["en","de","it","es"]},{"matchPath":"/aesthetic-nose-surgery","languages":["en","de","it","es"]},{"matchPath":"/aesthetic-breast-surgery","languages":["en","de","it","es"]},{"matchPath":"/skin-surgery","languages":["en","de","it","es"]},{"matchPath":"/body-contouring-surgery","languages":["en","de","it","es"]},{"matchPath":"/tummy-tuck-surgery","languages":["en","de","it","es"]},{"matchPath":"/vaser","languages":["en","de","it","es"]},{"matchPath":"/aesthetic-genital-surgery","languages":["en","de","it","es"]},{"matchPath":"/brazilian-lift","languages":["en","de","it","es"]},{"matchPath":"/transplantation","languages":["en","de","it","es"]},{"matchPath":"/contact","languages":["en","de","it","es"]},{"matchPath":"/blog","languages":["en","de","it","es"]},{"matchPath":"/thanks","languages":["en","de","it","es"]},{"matchPath":"/surgery-prices","languages":["en","de","it","es"]},{"matchPath":"/404","languages":["en","de","it","es"]},{"matchPath":"/:lang?/gallery","getLanguageFromPath":true},{"matchPath":"/:lang?/:path","getLanguageFromPath":true},{"matchPath":"/:lang?/blog/:path","getLanguageFromPath":true}]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
